.base-background{
    height: 100vh;
    .first-column{
        background: $primary url(img/postontzia.png) no-repeat left bottom;
        width:420px;
    }
    .second-column {
        background: url(img/back.png) no-repeat top left;
        background-size: cover;
        width: calc(100% - 420px);
    }
}

img {
    max-width: 100%;
    height: auto;
}