@font-face {
    font-family: 'HelveticaNeueLTCom-BdCn';
    src: url('./font/HelveticaNeueLTCom-BdCn.woff') format('woff'),
        url('./font/HelveticaNeueLTCom-BdCn.woff2') format('woff2');
}
@font-face {
    font-family: 'HelveticaNeueLTCom-Hv';
    src: url('./font/HelveticaNeueLTCom-Hv.woff') format('woff'),
        url('./font/HelveticaNeueLTCom-Hv.woff2') format('woff2');
}
@font-face {
    font-family: 'HelveticaNeueLTCom-HvCn';
    src: url('./font/HelveticaNeueLTCom-HvCn.woff') format('woff'),
        url('./font/HelveticaNeueLTCom-HvCn.woff2') format('woff2');
}
@font-face {
    font-family: 'Scout-Black';
    src: url('./font/Scout-Black.woff') format('woff'),
        url('./font/Scout-Black.woff2') format('woff2');
}
@font-face {
    font-family: 'Scout-Regular';
    src: url('./font/Scout-Regular.woff') format('woff'),
        url('./font/Scout-Regular.woff2') format('woff2');
}
h1{
    font-family: 'Scout-Black';
}
h2{
    font-family: 'Scout-Regular';
}
table{
    font-family: 'HelveticaNeueLTCom-Hv';
}
.mayor .bg-color,
.councilor .bg-color{
    font-family: 'HelveticaNeueLTCom-HvCn';
}
.councilorName{
    font-family: 'HelveticaNeueLTCom-BdCn';
}