.councilor{
    width:130px;
    margin:0 20px 1rem 0;
    background-color:white;
    text-align:center;
}
.mayor{
    width:280px;
    margin: 0 20px 1rem 0;
    background-color: white;
    text-align:center;
}
.councilorName{
    padding:10px;
    line-height:1rem;
}
