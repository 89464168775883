
h1 {
    font-size: 3.3rem !important;
}

h2 {
    font-size: 2.2rem !important;
}
.thin{
    font-weight:300;
}
.grey{
    color:#868484;
}
.ticker__item{
    text-transform: uppercase;
}
.maps{
    margin-top:-300px !important;
}

.chart-container {
    margin-top: 3rem;
    max-height: 770px;
    overflow: hidden;
}