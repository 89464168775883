* { box-sizing: border-box; }
$duration: 60s;

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {

  position: fixed;
  top: 50%;
  width: 100%;
  overflow: hidden;
  height: 4rem;
  background-color: rgba(#000, 1);
  padding-left: 100%;
  box-sizing: content-box;


  // .ticker {

  //   display: inline-block;
  //   height: 4rem;
  //   line-height: 4rem;
  //   white-space: nowrap;
  //   padding-right: 100%;
  //   box-sizing: content-box;

  //   -webkit-animation-iteration-count: infinite;
  //           animation-iteration-count: infinite;
  //   -webkit-animation-timing-function: linear;
  //           animation-timing-function: linear;
  //  -webkit-animation-name: ticker;
  //          animation-name: ticker;
  //   -webkit-animation-duration: $duration;
  //           animation-duration: $duration;

  //   &__item {

  //     display: inline-block;

  //     padding: 0 2rem;
  //     font-size: 2rem;
  //     color: white;

  //   }

  // }
  .location-name {
    background-color: white;
    color: black;
    height: 100%;
    display: inline-block;
    padding: 0 1rem 0 1rem;

  }

  .ticker {
    &__item {
      display: inline-block;
      padding: 0 2rem;
      font-size: 2rem;
      color: white;

    }
  }

  @for $i from 1 through 100 {
    $base: 10;
    $d: $base * $i;
    .ticker-#{$d} {
      display: inline-block;
      height: 4rem;
      line-height: 4rem;
      white-space: nowrap;
      padding-right: 100%;
      box-sizing: content-box;

      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
              animation-timing-function: linear;
     -webkit-animation-name: ticker;
             animation-name: ticker;
      -webkit-animation-duration: $d+s;
              animation-duration: $d+s;

    }
  }

}


