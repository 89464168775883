// $primary: #f35d3e; //2023 udal hauteskundeak
// $primary: #3a98cc; //2024 legebiltzarra
$primary: #4565ad; //2024 europakolegebiltzarra

$secondary: #393635;
$dark: $primary;
$light: white;
$navbar-light-color: rgb(42, 42, 42);
$navbar-light-hover-color: $secondary;
$navbar-light-brand-color: $primary;

$link-color: $secondary;
// Custom variable
$navbar-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
$nav-pills-link-active-bg: $primary;