
table {

    td,
    th {
        font-size: 1.1rem;
        font-weight: bold;

    }
    img{
        max-width:60px;
    }
}
.second-column {
    table{
        margin:1rem 0;

    }
}
table.table-35-percent {
    width: 35%;
}
table.town-table {
    td {
        background-color: white;
        font-size: 2.9rem !important;
        border: none;
        border-top: 1px solid black !important;
        vertical-align: middle;
    }

    th {
        border: none !important;
        font-size: 1.5rem !important;
    }
    .w-1{
        padding:0 1rem;
    }
    img{
        min-width:100px;
    }
}
.table-town-subheader th,
table.town-table.w-s th{
    background-color:white;

}
table.w-s .table-town-header th{
    background-color: #333;
}
table{
    border-top:1px solid transparent !important;
    border-bottom:1px solid black !important;
}
table.town-table{
    max-width:80%;
    margin:0 auto;
}
table td,
table th {
    font-weight: normal;
}
.table-town-header{
    background-color:#333;
    color:white;
    text-transform:uppercase;
}
table.w-s{
    max-width:300px;
}

table .partidua{
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.table-pp-name.text-white {
    width: 250px;
}
td.w-1{
    width:1%;
}
th.text-end, td.text-end{
    padding-right:1rem !important;
}
/* herri desberdinetako taulek zabalera bera izan dezaten*/
td.votes-cell{
    min-width: 160px;
}

table.map-table td{
        font-size: 1.3rem !important;
}
table.map-table .table-pp-towns{
        font-size: 1.9rem !important;
}

.past-map #downloadSVG, .actual-map #downloadSVG {
    display: none;
}
/**/
table.politicalparty-table{
    margin:0 auto;

    td {
        background-color: white;
        font-size: 1.9rem !important;
        border: none;
        border-top: 1px solid black !important;
        vertical-align: middle;
    }

    th {
        border: none !important;
        font-size: 1.5rem !important;
    }

    .w-1 {
        padding: 0 1rem;
    }

    img {
        min-width: 100px;
    }
}
